import { sendRewardAsync } from '@dx-ui/framework-conductrics';

import CopyBlock from '../copy-block/copy-block';
import { CONDUCTRICS_GOALS } from '../../helpers/constants';

import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';

export type PartnershipInfoProps = {
  partnership: NonNullable<GetHotelHomePageQuery['hotel']>['partnerships'][number];
};

const PartnershipInfo = ({ partnership }: PartnershipInfoProps) => {
  return (
    <CopyBlock
      headline={partnership.headline || ''}
      paragraphText={partnership.desc || ''}
      termsAndConditions={partnership?.relatedPolicy?.terms?.description}
      primaryButton={
        partnership.links[0]
          ? {
              label: partnership.links[0].label || '',
              url: partnership.links[0].url || '',
              isNewWindow: partnership.links[0].isNewWindow || false,
              variant: 'outline',
              onClick: async () => {
                await sendRewardAsync(CONDUCTRICS_GOALS.USP_CTA_ENGAGEMENT);
              },
            }
          : null
      }
      secondaryButton={
        partnership.links[1]
          ? {
              label: partnership.links[1].label || '',
              url: partnership.links[1].url || '',
              isNewWindow: partnership.links[1].isNewWindow || false,
              variant: 'outline',
            }
          : null
      }
      image={partnership.images?.[0]}
      logoUrl={partnership.logoUrl || ''}
    />
  );
};

export default PartnershipInfo;
